<template>
  <v-card class="popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      <div v-if="id !== undefined && id !== null">
        Edit Direct Mail
      </div>
      <div v-else>
        Create Direct Mail
      </div>
    </v-card-title>
    <v-divider v-if="$vuetify.breakpoint.xsOnly" />
    <v-card-text class="px-6 pt-5">
      <validation-observer ref="textInput">
        <v-row>
          <v-col cols="6">
            <CustomTextInput
              v-model="name"
              header="Mailing Name"
              class="flex-grow-1"
              placeholder="DM - Efficacy Mailer 8x11"
              :maxlength="200"
              required
            />
          </v-col>
          <v-col cols="6">
            <CustomTextInput
              v-model="vendorCreativeID"
              header="Vendor Creative ID"
              class="flex-grow-1"
              placeholder="Enter the identifier from the vendor here"
              :maxlength="200"
              required
            />
          </v-col>
          <v-col cols="6">
            <CustomDropdown
              v-model="selectedSource"
              header="Source"
              placeholder="Select source"
              :items="available_sources"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <CustomFileUpload
              v-model="imageFile"
              placeholder="Select Preview Image"
              header="Preview Image"
              accept="image/*"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <TagsInput
              v-model="tags"
              :items="availableTags"
              header="Tags"
              placeholder="Select / Add Tag(s) for this Creative"
            />
          </v-col>
        </v-row>
      </validation-observer>
      <v-card-actions class="d-flex justify-end align-center pa-0 mt-5">
        <div
          class="simple-text-button button--gray px-9"
          @click="$emit('dismiss')"
        >
          Cancel
        </div>

        <v-btn
          class="custom-button custom-button--blue px-15"
          height="34px"
          depressed
          @click="on_save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";
import {parseImageFileToBase64} from "@/utils/helper";
import TagsInput from "@/sharedComponents/TagsInput";

export default {
  name: "DirectMailEdit",
  components: {
    TagsInput,
    CustomTextInput,
    CustomDropdown,
    CustomFileUpload,
  },
  props: {
    directMailItem: {
      default: () => ({})
    },
    availableTags: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      available_sources: [],
      selectedSource : null,
      defaultSource : null,
      id: null,
      name: "",
      vendorCreativeID: "",
      image: null,
      imageFile: [],
      tags: [],
    }
  },
  created() {
    this.id = this.directMailItem.id;
    this.name = this.directMailItem.name;
    this.vendorCreativeID = this.directMailItem.vendorCreativeID;
    this.tags.push(...(this.directMailItem?.tags ?? []));
    if (this.directMailItem.sourceId !== undefined) {
      this.selectedSource = this.directMailItem.sourceId;
    }
    this.get_available_sources();
  },
  methods: {
    async on_save(){
      if (!await this.$refs.textInput.validate()) {
        return;
      }

      const payload = {
        id: this.id,
        name: this.name,
        vendorCreativeID: this.vendorCreativeID,
        source: this.selectedSource,
        requestImageFile: this.imageFile,
        tagItems: this.tags,
      };

      this.$emit('save', payload);
    },
    async get_available_sources() {
      let sourceData = (await this.$rest.source.get_collection({
        ignorePagination: true,
        sort: ["name:asc"]
      })).data.items;
      this.available_sources = sourceData.map(i => ({label: i.name, value: i.id}));

      this.defaultSource = sourceData.find( ({ isDefault }) => isDefault === true ).id;
      if (!this.selectedSource) {
        this.selectedSource = this.defaultSource
      }
    },
    async setFile(file) {
      if (file.length) {
        file = file[0];
      }

      if (!(file instanceof File)) {
        return;
      }

      parseImageFileToBase64(file).then(imgLoaded => {
        this.imageFile = {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        };
      }).catch(() => {
        this.imageFile = {};
      });
    },
  },
}
</script>
