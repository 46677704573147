<template>
  <v-container class="mt-3 px-sm-10 px-3 segments-accordion elevation-0" fluid>
    <PageHeader
      header-text="Direct Mail"
      regular-button-text="New Direct Mail"
      :regular-button-visible="$store.getters['user/hasWritePermission']"
      @regular-button-click="dialog = true"
    />

    <SearchFieldRowWithPagination
      search-field-placeholder="Search for direct mail"
      :current-page-number="directmail_page_number"
      :current-page-size="directmail_page_size"
      :count-total="directmail_total"
      :page-sizes="[25, 50, 100]"
      :init-search-string="search_string"
      @next-page="get_directmail_next_page"
      @prev-page="get_directmail_prev_page"
      @change-page-size="directmail_change_page_size"
      @search="(search_string) => on_search(search_string)"
    />

    <DirectMailTable
      :directmails="directmails"
      @action="handleAction($event)"
      @image-preview="openPreviewImage($event)"
    />

    <v-dialog v-model="dialog" scrollable max-width="560px" @click:outside="edited_directmail = {}">
      <DirectMailEdit
        v-if="dialog"
        :direct-mail-item="edited_directmail"
        :available-tags="tagNames"
        @save="save_directmail"
        @dismiss="dialog = false; edited_directmail = {}"
      />
    </v-dialog>

    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import DirectMailTable from "@/views/Channels/components/DirectMailTable";
import DirectMailEdit from "@/views/Channels/components/DirectMailEdit";
import { hasError } from "@/mixins/has_error";

export default {
  name: "DirectMail",
  metaInfo: {
    title: 'Direct Mail'
  },
  components: {
    DirectMailEdit,
    DirectMailTable,
    SearchFieldRowWithPagination,
    PageHeader,
    ImagePreviewDialog,
  },
  mixins: [ hasError ],
  data() {
    return {
      edited_directmail: {},
      dialog: false,
      directmails: [],
      directmail_total: 0,
      directmail_page_number: 1,
      directmail_page_size: 25,
      search_string: '',
      tags: [],
      tagNames: [],
      previewImageDialog: false,
      previewImageUrl: null,
      previewFullImageUrl: null,
      previewImageName: null,
    };
  },
  created() {
    this.get_available_directmails(this.$route.query.keyword);
    this.getTags();
  },
  methods: {
    openPreviewImage({ name, image }) {
      this.previewImageUrl = image.medium || image.large || image.full || image.small;
      this.previewFullImageUrl = image.full || image.large || image.medium || image.small;
      this.previewImageName = name;
      this.previewImageDialog = !!this.previewImageUrl;
    },

    async handleAction(action) {
      if (action.event.value === "delete") {
        await this.$rest.directmails.put_resource(action.item.id, {
          ...action,
          isArchived: true,
        })
        await this.get_available_directmails();
      } else if(action.event.value === "edit") {
        this.edited_directmail = {...action.item}
        await this.getTagsOfDirectMail();
        this.dialog = true;
      }
    },
    async on_search(search_string) {
      this.search_string = search_string;
      await this.get_available_directmails(search_string);
    },
    async save_directmail(directmail) {
      // pre-processing tgs
      directmail.tagItems = directmail.tagItems?.map((tagName) => {
        const tagInstance = this.tags.find((tag) => tag.name.toLowerCase() === tagName.toLowerCase());

        return tagInstance
          ? { id: tagInstance.id }
          : { name: tagName };
      }) ?? [];

      if (directmail.id){
        await this.$rest.directmails.put_resource(directmail.id, directmail)
        this.$notifier.success("Successfully edited the direct mail creative");
      } else {
        await this.$rest.directmails.post_resource(directmail)
        this.$notifier.success("Successfully added direct mail creative");
      }

      this.edited_directmail = {};
      this.dialog = false;

      await this.get_available_directmails();
      await this.getTags();
    },
    async directmail_change_page_size(size) {
      this.directmail_page_size = size;
      this.directmail_page_number = 1;
      await this.get_available_directmails();
    },
    async get_directmail_next_page() {
      this.directmail_page_number += 1;
      await this.get_available_directmails();
    },
    async get_directmail_prev_page() {
      this.directmail_page_number -= 1;
      await this.get_available_directmails();
    },
    async get_available_directmails(search_string) {
        if (search_string) {
          this.search_string = search_string;
        } else {
          this.search_string = "";
        }
        const resp = await this.$rest.directmails.get_collection({
          limit: this.directmail_page_size,
          page: this.directmail_page_number,
          name: this.search_string,
          sort: ['createdAt:desc'],
          isArchived: false,
          groups: ["tag_creative:get"],
        });
      this.directmails = resp.data.items;
      this.directmail_total = resp.data.totalCount;
    },
    async getTags() {
      const response = await this.$rest.tags.getCollection({
        fields: ['id', 'name'],
        ignorePagination: true,
      }).catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.tags = [...response.data.items];
      this.tagNames = this.tags.map((tagInstance) => tagInstance.name);
    },
    async getTagsOfDirectMail() {
      const response = await this.$rest.directmails.getTags(this.edited_directmail.id)
          .catch(this.showGenericError);

      if (!response.data) {
        return;
      }

      this.edited_directmail.tags = response.data.items.map((directMailTag) => directMailTag.tag.name);
    },
  },
};
</script>
